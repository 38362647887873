@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

* {
  outline: none;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

a,
span {
  font-weight: 400;
}

img {
  max-width: 100%;
  height: auto;
}

p,
ul {
  margin: 0;
  padding: 0;
}
.dm-section {
  min-height: 100vh;
  background-image: url("../Assets/imgs/bigmabg.webp");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
.main-content {
  max-width: 375px;
  text-align: center;
  margin: auto;
  padding: 0px 15px 30px;
  position: relative;
}
.dm-section .main-content .lp-absolute-content {
  margin: auto;
  border: 1.5px solid #097a9a99;
  border-radius: 16px;
  padding: 20px 30px;
  margin-top: -20px;
  position: relative;
  z-index: 1;
  background: #fff;
}
.dm-section .main-content .lp-absolute-content .landingtext {
  color: #3d2525;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 5px 0px 15px;
}
.dm-section .main-content .lp-absolute-content .input-wraper span {
  border-radius: 8px;
  text-align: center;
  padding: 12px;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  color: #6a6a6c;
  background: #12151c05;
  font-size: 15px;
  border: 2.21px solid #097a9a99;
}
.dm-section .main-content .lp-absolute-content .input-wraper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dm-section .main-content .lp-absolute-content .input-wraper .numberinput {
  width: 78%;
  padding: 12px;
  border-radius: 8px;
  color: #6a6a6c;
  background: #12151c05;
  font-size: 15px;
  height: 51px;
  box-sizing: border-box;
  border: 2.21px solid #097a9a99;
}
.dm-section
  .main-content
  .lp-absolute-content
  .input-wraper
  input::placeholder {
  color: #6a6a6c;
  font-size: 15px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.dm-section .main-content .lp-absolute-content .otpDiv {
  margin-top: 20px;
}
.dm-section .main-content .lp-absolute-content .otpDiv .otpinputWraper input {
  width: 100%;
  max-width: 55px;
  height: 55px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  text-align: center;
  color: #6a6a6c;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #12151c0d;
  border: 1.12px solid #12151c26;
}

.dm-section .main-content .lp-absolute-content .otpDiv .otpinputWraper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0px;
}

.dm-section .main-content .lp-absolute-content .subscribe-btn button {
  padding: 15px 0px;
  width: 100%;
  text-align: center;
  border-radius: 10px;
  background: #097a9a;
  color: #c7cacf;
  font-size: 14px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transform: 0.3s linear;
}
.dm-section .main-content .lp-absolute-content .subscribe-btn button:hover {
  opacity: 0.8;
}
.dm-section .main-content .lp-absolute-content .policytext {
  margin-top: 10px;
}
.dm-section .main-content .lp-absolute-content .policytext p {
  color: #a3a3ad;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  line-height: 22px;
  margin-bottom: 5px;
}
.dm-section .main-content .lp-absolute-content .policytext p a {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
}
.dm-section .main-content .lp-absolute-content .policytext p a:hover {
  text-decoration: underline;
}
.dm-section .main-content .lp-absolute-content .policytext p:last-child {
  margin-bottom: 0px;
}
.dm-section .main-content .lp-absolute-content .policytext .checkflex {
  display: flex;
  align-items: flex-start;
}
.dm-section .main-content .lp-absolute-content .policytext .checkflex input {
  margin-top: 6px;
}
.error {
  color: red;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
}
.invalidotp {
  color: #ffb4ab;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
}
.success {
  color: #a0e5ae;
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  margin-top: 10px;
}
.otpcounter a,
.otpcounter p {
  color: #c7cacf;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}
.otpcounter {
  margin-bottom: 15px;
  font-size: 12px;
  color: #12151c;
  text-align: center;
}
.otpcounter a {
  text-decoration: none;
  color: #097a9a;
}
.policttext {
  color: #393939;
  font-size: 14px;
  text-align: center;
  margin: 15px 0px 5px;
}
.privacypolicy {
  color: #393939;
  font-size: 12px;
  text-align: center;
  transition: 0.3s linear;
  text-decoration: underline;
}
.privacypolicy:hover {
  opacity: 0.7;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.modal {
  background-color: white;
  padding: 30px 20px;
  border-radius: 8px;
  max-width: 330px;
  width: 100%;
  overflow: auto;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}
.modal-content img {
  margin-bottom: 8px;
}
.modal-content p {
  margin-bottom: 6px;
  font-size: 13px;
  line-height: 21px;
}
.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  color: #333;
  font-size: 14px;
  font-weight: 700;
}
.terms-wraper {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  margin-top: 15px;
}
.terms-wraper .dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #097a9a99;
}
.pr-modal {
  background-color: white;
  padding: 25px;
  border-radius: 8px;
  width: 100%;
  max-width: 75%;
  overflow: auto;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}
.pr-modal .modalHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pr-modal .modalHead .modal-heading {
  color: rgba(2, 126, 191, 1);
  font-size: 24px;
  font-weight: 500;
}
.pr-modal .modalHead .modal-heading span {
  color: #000;
}
.pr-modal .pr-close-modal {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  color: #333;
  font-size: 14px;
  font-weight: 700;
}
.pr-modal-content ul {
  margin-top: 20px;
  max-height: 410px;
  overflow: auto;
}
.pr-modal-content ul li {
  color: rgba(12, 61, 92, 1);
  font-size: 15px;
  font-weight: 300;
  text-align: left;
  list-style-type: disc !important;
  margin-bottom: 12px;
  margin-left: 22px;
}
@media (max-width: 767px) {
  .modal {
    max-width: 80%;
  }
  .pr-modal-content ul li {
    font-size: 13px;
    margin-bottom: 8px;
  }
  .pr-modal-content ul {
    max-height: 320px;
  }
}
@media (max-width: 370px) {
  .dm-section .main-content .lp-absolute-content {
    padding: 20px 12px;
  }
}
